  body {
    font-family: 'Roboto' !important;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* MUI CSS START */
.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #B2BAC2 !important;
}


.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #E0E3E7 !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: red !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 45, 114, 1)!important;
}
.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(225, 0, 0, 1)!important;
}
label.Mui-focused.Mui-error  {
  color: rgba(225, 0, 0, 1)!important;
}
label.Mui-focused {
  color: rgba(0, 45, 114, 1) !important;
}
.MuiInputLabel-root.Mui-focused {
  color: rgba(0, 45, 114, 1) !important;
}
/* MUI CSS END */
/* MODAL CSS START*/
.view-modal-header{
margin-bottom: 50px;
}
.view-modal-section img{
  margin: 5px;
  margin-right: 15px;
}
.view-modal-parent-section{
  overflow: scroll;
  max-height: 400px;
}
.delete-btn{
  background-color: #A82525 !important;
  color: white !important;
  font-weight: 600 !important;
}
.cancel-btn{
  background-color: white !important;
  color: rgba(0, 45, 114, 1)!important;
  font-weight: 600 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: rgba(0, 45, 114, 1) !important;
  }
/* MODAL CSS END*/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.PL-select-city select{
  display: inline;
  width: 200px;
  margin-left: 20px;
}
.btn-primary{
  background-color: rgba(0, 45, 114, 1) !important;
  border-color: rgba(0, 45, 114, 1) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  padding: 0 20px; /* Add some padding to prevent overflow */
  box-sizing: border-box; /* Include padding in element's total width and height */
}

h2 {
  margin: 0;
  font-size: 18px; /* Smaller font size */
  font-weight: bold;
}

p {
  font-size: 12px; /* Smaller font size */
}

.retry-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #001f60;
  color: #fff;
  border: none;
  padding: 6px 12px; /* Smaller padding */
  font-size: 12px; /* Smaller font size */
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 12px; /* Smaller margin */
}

.retry-button:hover {
  background-color: #0056b3;
}

.retry-button:focus {
  outline: none;
}

