.view-drawer-container {
    position: fixed;
    top: 0;
    right: -320px;
    /* Initially hidden off-screen */
    width: 300px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 13px 4px #0000001F;
    transition: right 0.3s ease;
    z-index: 99999;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.view-drawer-container.open {
    right: 0;
}

.view-drawer-main {
    width: 300px;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Drawer header */
.view-drawer-header {
    display: flex;
    justify-content: space-between;
    height: 26px;
    padding: 24px;
}


/* Backdrop styling */
.view-drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    z-index: 99998;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.view-drawer-backdrop.open {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: auto;
}

.close-icon {
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
}