.modal-container {
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 50%; /*Center horizontally */
  transform: translateX(-50%);
  z-index: 1000; /* Ensure it appears above other elements */
  width: 80%;
}
.css-htszrh-MuiButton-startIcon {
  display: inherit;
  margin-right: 0px;
  margin-left: 0px;
}
.svg_hover{
  color: black;
}
.svg_hover:hover{
  color: blue;
}