@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 16px;
}

.form-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.container-fluid {
    width: 100vw;
    overflow-x: hidden;
}

.logo {
    max-width: 229px;
    height: 72px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 100%;
    height: auto;
}

.login-form {
    width: 100%;
}

.login-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    grid-gap: 16px;
    gap: 16px;
}

.fields-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.email,
.password,
.check-box {
    width: 100%;
}

.login-button {
    width: 100%;
    height: 48px;
}

.login-button button {
    width: 100%;
    height: 48px;
    background-color: #002D72;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button button:hover {
    background-color: #004BB4;
}

.text-center a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #4E4E4E;
}

.footer-1 {
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding-top: 60px;
}

.footer-1 p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: var(--grey-700, #131316);
}

.main-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
    height: 562px;
    width: 525px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-right {
    width: 525px;
    height: 334px;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 10px;
    background: #F7FAFC;
    position: relative;
    box-sizing: border-box;
}

.title {
    max-width: 255px;
    top: 45px;
    left: 44px;
    grid-gap: 16px;
    gap: 16px;
    position: absolute;
}

.title p {
    font-family: Roboto;
    font-size: 34px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #002D72;
    margin-bottom: 16px;
}

.sub-title {
    max-width: 255px;
    top: 144px;
    left: 44px;
    grid-gap: 0px;
    gap: 0px;
}

.sub-title span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #718096;
    display: block;
}

.mobile-image {
    width: 282px;
    height: 209px;
    top: 134px;
    left: 318px;
    grid-gap: 0px;
    gap: 0px;
}

.bottom-right {
    width: 524px;
    height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title-bottom {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-bottom p {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #F7FAFC;
    margin: 0;
}

.sub-title-bottom {
    width: 90%;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-title-bottom span {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.7px;
    text-align: center;
    color: #CFD9E0;
    margin: 0;
}

@media (max-width: 768px) {
    .container-fluid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        overflow-x: hidden;
    }

    .row {
        flex-direction: column;
    }

    .col-6 {
        width: 100%;
    }

    .col-lg-6:nth-child(2),
    .col-md-6:nth-child(2),
    .col-sm-12:nth-child(2) {
        display: none;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding: 16px;
    }

    .form-container {
        width: 100%;
        max-width: 400px; 
    }

    .login-fields {
        width: 100%;
    }

    .fields-container {
        width: 100%;
    }

    .login-button button {
        width: 100%;
    }

    .footer-1 {
        margin-top: 16px;
        text-align: center;
    }

    .login-form {
        margin-bottom: 24px;
    }

    .download-button {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }

    .text-center {
        margin-top: 16px;
    }
    .download-app-button {
        display: block !important;
    }
}

@media (min-width: 769px) {
    .download-app-button {
        display: none !important; 
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
  .colored::after{
    border: 6px solid #31353D !important;
    border-color: #31353D transparent #31353D transparent !important;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  body {
    font-family: 'Roboto' !important;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* MUI CSS START */
.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #B2BAC2 !important;
}


.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #E0E3E7 !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: red !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 45, 114, 1)!important;
}
.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(225, 0, 0, 1)!important;
}
label.Mui-focused.Mui-error  {
  color: rgba(225, 0, 0, 1)!important;
}
label.Mui-focused {
  color: rgba(0, 45, 114, 1) !important;
}
.MuiInputLabel-root.Mui-focused {
  color: rgba(0, 45, 114, 1) !important;
}
/* MUI CSS END */
/* MODAL CSS START*/
.view-modal-header{
margin-bottom: 50px;
}
.view-modal-section img{
  margin: 5px;
  margin-right: 15px;
}
.view-modal-parent-section{
  overflow: scroll;
  max-height: 400px;
}
.delete-btn{
  background-color: #A82525 !important;
  color: white !important;
  font-weight: 600 !important;
}
.cancel-btn{
  background-color: white !important;
  color: rgba(0, 45, 114, 1)!important;
  font-weight: 600 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: rgba(0, 45, 114, 1) !important;
  }
/* MODAL CSS END*/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.PL-select-city select{
  display: inline;
  width: 200px;
  margin-left: 20px;
}
.btn-primary{
  background-color: rgba(0, 45, 114, 1) !important;
  border-color: rgba(0, 45, 114, 1) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  padding: 0 20px; /* Add some padding to prevent overflow */
  box-sizing: border-box; /* Include padding in element's total width and height */
}

h2 {
  margin: 0;
  font-size: 18px; /* Smaller font size */
  font-weight: bold;
}

p {
  font-size: 12px; /* Smaller font size */
}

.retry-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #001f60;
  color: #fff;
  border: none;
  padding: 6px 12px; /* Smaller padding */
  font-size: 12px; /* Smaller font size */
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 12px; /* Smaller margin */
}

.retry-button:hover {
  background-color: #0056b3;
}

.retry-button:focus {
  outline: none;
}


.bg-12 {
  width: 100%;
  /* position: fixed; */
  z-index: 99;
  /* border-radius: 12px; */
  background-color: #FFF;
  /* border-bottom: 1px solid black; */
  /* box-shadow: 1px 1px 1px 0 rgb(0 0 0 / 16%); */
  /* margin-top: 16px;  */
  height: 72px;


}

.header-avatar-wrapper {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.header-avatar-wrapper div {
  background: #002D72;
  border-radius: 100%;
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  margin-top: 0px;
  align-items: center;
}

.header-avatar-wrapper div p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0px;
}

.avatar-dropdown-menu {
  top: 55px !important;
  border-radius: 4px;
  background: var(--background-paper-elevation-3, #FFF);

  /* elevation/3 */
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);
  width: auto;
  height: auto;
  left: -16px;
}

.dropdown-menu {
  border-radius: 4px !important;
}

.dropdown-toggle::after {
  margin-top: auto;
  margin-bottom: auto;
}

.name_font {
  color: var(--Light-Theme-Heading-Text, #151924);
  /* Body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
}

.designation_font {
  color: var(--grey-500, var(--grey-500, #505157));
  /* Footnote/description */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.profile_section {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  cursor: pointer;

  /* margin-top: 3px; */
}

.logout_section {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  padding: 0px 16px;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.profile_section:hover,
.logout_section:hover {
  background-color: #F7F7FB;
}

.line_hr {
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: auto;
}

.icon-buttons-container {
  display: flex; /* Ensures the icons are in a row */
  align-items: center; /* Vertically aligns icons */
  justify-content: flex-end; /* Pushes the icons to the right end */
  grid-gap: 12px;
  gap: 12px; /* Adds space between the icons */
  margin-right: 16px; /* Adjust this as needed for proper spacing from the right edge */
}
.icon-button {
    width: 40px;
    height: 40px;
    border-radius: 88px;
    background: var(--Gray-Colors-Gray-2, #F3F4F6); /* Background color */
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px; /* Space inside for potential child elements */
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color change */
  }
  
  .icon-button:hover {
    background-color: #e2e3e5; /* Slightly darker on hover for feedback */
  }
  
  .icon-button:active {
    background-color: #d1d3d5; /* Even darker on active click */
  }
  
@font-face {
    font-family: 'SFProDisplayBold';
    src: url(/static/media/SFProDisplay-Bold.aa9f6c10.ttf) format('truetype');
    /* font-weight: normal; */
    /* font-style: normal; */
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.sidebar-wrapper ul li a div img,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    transition: all 0.4s ease;

}


/*----------------page-wrapper----------------*/

.page-wrapper {
    height: 100vh;
}

.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}

.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}

/*----------------toggeled sidebar----------------*/
.task-drawer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.manage-drawer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.submenu_headings {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}


.sidebar-submenu {
    display: none;
    padding-left: 20px;
}

.sidebar-dropdown.active .sidebar-submenu {
    display: block;
}

.page-wrapper.toggled .sidebar-wrapper {
    width: 260px;
    left: 0px;
}

.sidebar-menu-title {
    width: auto;
}

@media screen and (min-width: 738px) {

    /* Case 1: If .page-wrapper has .toggled, padding-left should be 280px */
    .page-wrapper.toggled .page-content {
        padding-left: 270px;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
    }

    /* Case 2: If .page-wrapper has .subitemclicked and does NOT have .toggled, padding-left should be 280px */
    .page-wrapper.subitemclicked:not(.toggled) .page-content {
        padding-left: 330px;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
    }

    /* Case 3: If .page-wrapper does NOT have .toggled and does NOT have .subitemclicked, padding-left should be 70px */
    .page-wrapper:not(.toggled):not(.subitemclicked) .page-content {
        padding-left: 70px;
        transition: padding-left 0.4s ease;
    }

    /* Case 4: If .page-wrapper has both .toggled and .subitemclicked, padding-left should still be 280px */
    .page-wrapper.toggled.subitemclicked .page-content {
        padding-left: 280px !important;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
        position: relative;
    }
    .page-wrapper.toggled.subitemclicked .page-content::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: rgba(0, 0, 0, 0.1); */
        pointer-events: auto;
        cursor: default;
        z-index: 1;
    }
    
}
/*----------------show sidebar button----------------*/
#show-sidebar {
    position: fixed;
    left: 0;
    top: 5px;
    border-radius: 4px;
    transition-delay: 0.3s;
    transition: 0.4s ease;
    z-index: 999;
    box-shadow: 8px #00000014;
}
#show-sidebar i {
    color: #bdbdbd;
}

#show-sidebar i:hover {
    color: #fff;
}

.page-wrapper.toggled #show-sidebar {
    left: -40px;
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    height: 91vh;
    position: fixed;
    top: 72px;
    z-index: 10000;
    transition: 1s ease;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}

/*----------------sidebar-content----------------*/
.sidebar-content {
    height: 98%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    border: 1px solid;
    border-color: #EDEDED;
}

.sidebar-content::-webkit-scrollbar {
    display: none;
}


.download_app {
    bottom: 25px;
    left: 5px;
    width: auto;
    padding: 8px;
}

.download_app button {
    width: 100%;
    padding: 6px 10px 6px 10px;
    border-radius: 6px;
    border: 2px solid #002D72;
    background-color: #FFFFFF;
    color: #002D72;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
    font-size: 16px;
}

.sidebar-content.desktop {
    overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.sidebar-wrapper .sidebar-brand>a {
    font-weight: bold;
    font-size: 20px;
    flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
    line-height: 31px;
    width: 100%;
}

/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
    float: left;
}

.sidebar-wrapper .sidebar-header .user-info>span {
    display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search>div {
    padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-border-class {
    border-radius: 8px;
    background-color: var(--UI-background-white, #FFF);
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 17px;
    display: inline-block;
}

.sidebar-menu .sidebar-menu-list {
    margin-top: 0px;
}

.menu-item:hover {
    background-color: #F7F7FB;
    border-radius: 4px;
}

.sidebar-open .menu-item:hover {
    background-color: #F7F7FB;
    border-radius: 4px;
}

.dashboard-dropdown .menu-item.active>a {
    border-radius: 4px;
}

.task-dropdown .menu-item.active>a {
    color: #1E293B !important;
    border-radius: 4px;
    background: var(--primary-base, #DEE2E6);
    border-radius: 4px;
}

.manage-dropdown .menu-item.active>a {
    color: #1E293B !important;
    background: #DEE2E6;
    border-radius: 4px;
}

.billing-dropdown .menu-item.active>a {
    color: white !important;
}

.invoice-dropdown .menu-item.active>a {
    color: white !important;
}

.billing-dropdown.active>a {
    color: #002d72 !important;
}

.invoice-dropdown.active>a {
    color: #002d72 !important;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #002d72 !important;
}

#show-sidebar i {
    color: #002d72 !important;
}

.chiller-theme .sidebar-footer {
    background: transparent !important;
    box-shadow: -5px 0px 10px #ccc !important;
    border-top: none !important;
}

.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 0px 8px 10px;
    font-size: 16px;

}

.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    font-weight: 400;
    font-family: Roboto;

}

.sidebar-wrapper .sidebar-menu ul li a:hover>i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-dropdown a {
    text-align: left !important;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
    font-family: "Font Awesome 6 Free";
    font-weight: 510;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 14px;
    color: #4B5563;
}


.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    width: 100%;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    margin-left: 24px;
    font-size: 13px;
}

.sidebar-dropdown.no-arrow>a::after {
    content: none !important;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}

.sidebar-footer>a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.sidebar-footer>a .notification {
    position: absolute;
    top: 0;
}

.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}

.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
    /* display: inline-block; */
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
    transition: 0.2s ease;
    height: 92vh;
}


.page-wrapper .page-content>div {
    height: 98%;
    overflow-y: scroll;
    /* gap: 24px; */
    background-color: #F9FAFB;
    border-top-left-radius: 24px;
    padding: 32px;
    margin-bottom: 1px;
}

.page-wrapper .page-content {
    overflow-x: hidden;
}

.font-20 {
    font-size: 20px !important;
}

/*------scroll bar---------------------*/

/* Customize scrollbar like Mac OS */
.page-wrapper .page-content>div::-webkit-scrollbar {
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.page-wrapper .page-content>div::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.page-wrapper .page-content>div::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #FFF;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #1E293B;
}


.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #FFF;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div {
    background-color: none !important;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #1E293B;
}

.toggled-sideBar {
    left: 0px !important;
    transition: 1s ease;
}

.chiller-theme .sidebar-footer {
    background: #f5f5f5;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

.icon-wrapper {
    width: 30px;
    height: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
}

.bg-billing {
    background-color: rgba(0, 46, 116, 0.2) !important;
}

.bg-invoice {
    background-color: rgba(0, 46, 116, 0.2) !important;
}

.text-billing:hover {
    color: #002d72 !important;
}

.text-invoice:hover {
    color: #002d72 !important;
}

.link-style {
    padding: 8px 10px;
    text-decoration: none;
    color: inherit !important;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}


.task-drawer-wrapper {
    max-height: 91%;
    top: 72px;
    z-index: 999;
    transition: 0.4s ease;
    background: #FFF;
}

.task-drawer-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.manage-drawer-wrapper {
    max-height: 91vh !important;
    top: 72px;
    z-index: 999;
    transition: 0.4s ease;
    background: #FFF;
}

.manage-drawer-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.logout-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.sidebar-menu {
    height: 100%;
}

.drawer-heading {
    padding: 0px 20px;
    margin-bottom: 20px;
    font-family: 'SFProDisplayBold';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    color: #1E293B;
}
.avatar {
  border: 0.3rem solid rgba(#fff, 0.3);
  margin-top: 0px;
  margin-bottom: 1rem;
  max-width: 7rem;
}

.employee-wrapper {
  display: flex;
}

.employee-wrapper .card {
  width: 100%;
}

.avatar-wrapper {
  flex-basis: 100%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;

}

.avatar-set {
  background: green;
  border-radius: 100%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.avatar-wrapper div p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}

.form-group .required.form-control-label:after {
  content: "*";
  color: red;
  font-weight: 900;
}

.text-red {
  color: red !important;
}

.hidden-input {
  color: transparent;
  text-shadow: 0 0 0 #000;
  /* Make text shadow the same color as the background */
  background-color: transparent;
  /* Ensure the background is transparent */
  border: none;
  /* Remove border if necessary */
  outline: none;
  /* Remove focus outline if necessary */
}

.employees_set {
  color: rgba(58, 58, 58, 0.48);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.personal {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}


.avatar-dp-set {
  background: green;
  border-radius: 100%;
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
}

.avatar-dp-set p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 55px;
  margin-bottom: 0px;
}

.person_name {
  color: var(--Light-Theme-Heading-Text, #151924);
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 10px;
}

.designation_data {
  color: var(--grey-500, var(--grey-500, #505157));

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.details_text {
  color: var(--Neutral-8, #595959);


  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.set_text_gap {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;

}

.label_data {
  color: #000;

  /* Body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.react-datepicker-wrapper {
  width: 100%;
}
/* .search-input:focus-visible{
    border: 1px solid #ccc !important;
}
.search-input:focus{
    outline: 0px solid 
    rgba(0, 45, 114, 0.7);
}
.search-input::placeholder{
    color: rgba(58, 58, 58, 0.48) !important;
} */
.no-scroll-y {
	overflow-y: hidden;
}

/* Preloader */
.ctn-preloader {
	align-items: center;
  cursor: none;
	display: flex;
  height: 100%;
  justify-content: center;
	position: fixed;
	left: 0;
  top: 0;
	width: 100%;
  z-index: 9999;
}

.ctn-preloader .animation-preloader {
	position: absolute;
  z-index: 9999;
}

/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
	border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #000000; /* No se identa por orden alfabetico para que no lo sobre-escriba */
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  font: bold 5em 'Montserrat', sans-serif;
	text-align: center;
  margin: auto;
  display: block;
	-webkit-user-select: none;
	        user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #000000;
  content: url(/static/media/logofooter.fc1f5849.png);
  left: 0;
  opacity: 0;
  /* position: absolute; */
  top: 0;
  transform: rotateY(-90deg);
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
	color: rgba(0, 0, 0, 0.2);
	position: relative;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.3s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.5s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 0.7s;
}

.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
	position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.ctn-preloader .loader-section.section-left {
  left: 0;
}

.ctn-preloader .loader-section.section-right {
  right: 0;
}

/* Efecto de fade en la animación de cargando */
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* Efecto de cortina */
.loaded .loader-section.section-left {
  transform: translateX(-101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

.loaded .loader-section.section-right {
  transform: translateX(101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

/* Animación del preloader */
@keyframes spinner {
	to {
		transform: rotateZ(360deg);
	}
}

/* Animación de las letras cargando del preloader */
@keyframes letters-loading {
  0%,
  75%,
  100% {
  	opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

/* Tamaño de portatil hacia atras (portatil, tablet, celular) */
@media screen and (max-width: 767px) {
	/* Preloader */
	/* Spinner cargando */	
	.ctn-preloader .animation-preloader .spinner {
		height: 8em;
		width: 8em;
	}

	/* Texto cargando */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 3.5em 'Montserrat', sans-serif;
	}
}

@media screen and (max-width: 500px) {
	/* Prelaoder */
	/* Spinner cargando */
	.ctn-preloader .animation-preloader .spinner {
		height: 7em;
		width: 7em;
	}

	/* Texto cargando */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 2em 'Montserrat', sans-serif;
	}
}
/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

/* Bootstrap Icons */

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    /* color: #002D72 !important; */
    color: #2196F3 !important;
    font-weight: 700 !important
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: rgba(11, 100, 233, 0.8) !important;
    font-weight: 700 !important
}
.nav-tabs .nav-link {
    font-weight: 700;
}
.summary-button{
width: 206px;
height: 38.8px;
padding: 6px 15px 6px 15px;
border-radius: 2px;
border: 1px;
}

.makeStyles-root-1{
    width: -webkit-max-content;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.Linechart {
    font-family: sans-serif;
    text-align: center;
  }
  /* Customize scrollbar for webkit browsers */
::-webkit-scrollbar {
  /* width: 16px; */
}

::-webkit-scrollbar-track {
  /* background-color: #fff; */
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* Hide scrollbar buttons (not supported in Firefox and IE) */
::-webkit-scrollbar-button {
  /* display: none; */
}
 
.Barchart {
    font-family: sans-serif;
    text-align: center;
  }

 
.StackedBarchart {
    font-family: sans-serif;
    text-align: center;
  }
.Tabular {
  font-family: sans-serif;
  text-align: center;
}

.main-header-table {
  height: 56px;
  border-bottom: 1px solid #0000001F;
  background: #FFFFFF;

}

.main-header-table-td {
  height: 56px;
  padding: 16px 0px 0px 0px;
  font-family: Roboto;
  font-size: 14px;

  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
  font-weight: bold;

}

.main-header-table-td-title {
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
  font-weight: bold;
}

.main-table-row {
  height: 72px;
  border: 0px 0px 1px 0px;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 8px;
  margin: 16px;
  grid-gap: 16px;
  gap: 16px;
  background: #FFFFFF;
  padding: 0px;
}

.main-table-employee {
  height: 72px;

}

.main-table-td {
  height: 52px;
}

.main-header-table-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
}

.expansion-table-header {
  height: 56px;
  border: 0px 0px 1px 0px;
  background: #E6F4FF;
  border-bottom: 1px solid #E6F4FF
}

.expansion-table-header-td {
  height: 56px;
  padding: 16px 0px 0px 0px;

}

.expansion-table-header-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
}

.expansion-table-row {
  height: 54px;
  border: 0px 0px 1px 0px;
  border-bottom: 1px solid #3A3A3A7A
}

.expansion-table-row-td {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}

.expansion-table-row-td-action {
  max-width: 54px;
  height: 54px;
  padding: 8px 16px 8px 16px;
  margin: 8px;
}

.expansion-table-row-td-priority {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}

.expansion-table-row-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
}

.task-status {
  height: 24px;
  padding: 6px, 8px, 6px, 8px;
  border-radius: 4px;
  background: #7E7E7E1A;

}

.task-priority {
  height: 24px;
  padding: 6px, 8px, 6px, 8px;
  border-radius: 4px;
  background: #FF00001A;
}

.table-container {
  overflow-x: auto;
}

.expansion-table {
  width: 100%;
}

.expansion-table-row-td-status {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}
.manage_input{
    width: 100%;
    height: 80%;
    padding-left: 10px;
    color: rgba(58, 58, 58, 0.48);
    border-radius: 2px;
    background-color: #F5F5F5 ;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.heading_role{
    color: var(--text-primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: bold;
line-height: 24px; 
letter-spacing: 0.17px;
}
.table_text{
    color: #272727;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 468px !important;
    height: 54px !important;
    border-radius: 8px !important;
    background: var(--Foundation-Light-White, #FFF) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04) !important;

} */
/* .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    width: 94% !important;
}
.css-1m66l2e {
    width: 100% !important;
}
.css-1siprgr{
    width: 100% !important;
}
.css-1v4ccyo{
    width: 468px !important;
} */
.login-button{
    display: flex !important;
}
.address-cell {
    max-width: 25%;
    word-wrap: break-word;
  }
  .set_label{
    display: flex;
    align-items: flex-start;
  }

  .toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
      
  }
  
@font-face {
    font-family: 'SFProDisplayRegular';
    src: url(/static/media/SFProDisplay-Regular.1e1c0e29.ttf) format('truetype');
}

@font-face {
    font-family: 'SFProDisplayMedium';
    src: url(/static/media/SFProDisplay-Medium.c9c3d293.ttf) format('truetype');
}

@font-face {
    font-family: 'SFProDisplaySemibold';
    src: url(/static/media/SFProDisplay-Semibold.e563de5c.ttf) format('truetype');
}

.body-extra-small-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
}

.body-extra-small-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
}

.body-small-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
}

.body-medium-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    /* text-underline-position: from-font; */
}

.body-large-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
}

.body-small-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
}

.body-medium-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 16px;
    line-height: 24px;
    text-underline-position: from-font;
}


.heading6-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    padding: 6px;
    text-underline-position: from-font;
}

.heading6-semibold {
    font-family: 'SFProDisplaySemibold';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
}
.heading_role{
    color: var(--text-primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;

/* components/table-header */
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: bold;
line-height: 24px; /* 171.429% */
letter-spacing: 0.17px;
}
.toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
      
  }
.address-cell {
    max-width: 25%;
    word-wrap: break-word;
  }
  .set_label{
    display: flex;
    align-items: flex-start;
  }

  .toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
      
  }
  .address-cell {
    max-width: 25%;
    word-wrap: break-word;
  }
  .set_label{
    display: flex;
    align-items: flex-start;
  }
  .toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
  }
  .document-grid {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 10px;
  }
  .add_document {
    width: 162px;
    height: 114px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 10px;
    position: relative; 
    overflow: hidden;
  }
  .add_document:hover {
    background-color: #002D72;
  }
  .trash-icon {
    position: absolute;
    display: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    stroke: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .add_document:hover .trash-icon {
    display: block;
  }
.no-files-text {
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
margin-top: 5px;
}

.modal-container {
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 50%; /*Center horizontally */
  transform: translateX(-50%);
  z-index: 1000; /* Ensure it appears above other elements */
  width: 80%;
}
.css-htszrh-MuiButton-startIcon {
  display: inherit;
  margin-right: 0px;
  margin-left: 0px;
}
.svg_hover{
  color: black;
}
.svg_hover:hover{
  color: blue;
}
.multi-step-wrapper ol {
    display: flex;
    padding-right: 2rem;
}

.multi-step-wrapper li {
    flex: 1 1;
    border-bottom: 6px solid #002D72;
    height: 50px;
}

.multi-step-wrapper li:nth-child(1) {
    border-image: linear-gradient(to left,
            #002D72 50%, #FFF 50%) 100% 1;
}

.multi-step-wrapper li:nth-child(4) {
    border-image: linear-gradient(to right,
            #002D72 50%, #FFF 50%) 100% 1;
}

.multi-step-wrapper li span {
    display: none;
}

.multi-step-wrapper li::before {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    line-height: 2em;
    bottom: -30px;
}

.multi-step-wrapper .go433304200::before {
    background-color:
        #002D72;
    font-weight: 900;
}

.multi-step-wrapper .go3842760039::before {
    background-color:
        #002D72;
    font-weight: 900;
    line-height: 1.8em;
}

.multi-step-wrapper .go2335061104::before {
    content: '';
    background-color:
        #002D72;
}

.multi-step-wrapper div button {
    width: 120px;
    height: 40px;
    background-color:
        #002D72;
    color: white;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 1.3em;
}

.task-pagination-wrapper {
    float: right;
    margin-right: 30px;
    margin-top: 20px;
}

.task-pagination-wrapper button {
    margin-right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 6px;
}

.task-pagination-wrapper button.active {
    background-color:
        #002D72;
    color: #FFF;
}

.task-table-header {
    font-weight: 700 !important;
    font-size: 14px !important;
    font-style: normal;
    text-transform: none !important;
}

.task-filter-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 5px;
    padding: 10px;
}

.task-filter-wrapper div .basic-multi-select .select__control {
    height: auto;
    width: 100%;
}

.task-filter-reset-btn {
    width: 100%;
}

.task-type {
    border: 2px solid #ccc;
    border-radius: 7px;
    display: block;
    font-size: 1em;
    padding: 10px;
    font-weight: 600;
    width: 90%;
    -webkit-appearance: none;
            appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.task-type:focus-visible {
    border: 2px solid #ccc !important;
}

.task-type span {
    font-size: 28px;
}

.heading_notes {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notes_input {
    border-radius: 8px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin: 9px;
    padding: 6px;
}

.notes_input::placeholder {
    padding: 3px;
}

.notes_paragraph {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 14px;
    align-items: flex-start;
    grid-gap: var(--1, 8px);
    gap: var(--1, 8px);
    margin: 9px;
}

.notes-avatar-wrapper {
    border-radius: 24px;
    background: #D9D9D9;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin: 9px;
}

.no-line-break {
    white-space: nowrap;
}

.notes_name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

/* Customize scrollbar for webkit browsers */
::-webkit-scrollbar {
    /* width: 16px; */
}

::-webkit-scrollbar-track {
    /* background-color: #fff; */
}

::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* Hide scrollbar buttons (not supported in Firefox and IE) */
::-webkit-scrollbar-button {
    /* display: none; */
}
/* .task-details {
  background-color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin: 15px auto;
    border-radius: 10px;
    max-width: 770px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    min-height: auto;
    overflow: scroll;
  } */

/* .top-heading {
    height: 48px;
    width: 688px;
    display: space-between;
  } */
/* .title {
    height: 42px;
    width: 228px;
  } */


/* .other-details{
    color: #909090;
    background-color: #FF4D001A;
    height: 28px;
    border-radius: 4px;
  } */
/* .priority_name{
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .priority_color{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statuses{
    margin-left: -9%;
    display: flex;
    justify-content: center;
    text-align: center;
    gap:15px;
  }
  .task-details h1 {
    font-size: 24px;
    color: 	#000000;
    font-family: Roboto;
    font-weight: 500;
    
    
    
  }
  
  .task-details h2 {
    font-size: 22px;
    color: 	#505050;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .task-details p {
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 20px;
    width: auto;
    display: flex;
    align-items: center;
    text-align: center;
    height: auto;
    border-radius: 4px;
    justify-content: space-between;
  }
  
  .task-details ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
  } */

/* .task-details li {
    margin-bottom: 5px;
  }
   
  .map-container {
    height: 200px;
    width: 688px;
    margin-top: 20px;
    margin-bottom: 40px;
  
  }
  .icon-wrapper-detail{
    border-radius: 15px;
  } */

/* .bg-task-dark{
    background-color: rgba(0, 46, 116, 0.2) !important;
  }
  .bg-manage-dark{
    background-color: rgba(222, 12, 21, 0.3) !important;
  }
  .bg-billing-dark{
    background-color: rgba(0, 133, 44, 0.4) !important;
  }
  .bg-invoice-dark{
    background-color: rgba(255, 178, 10, 0.4) !important;
  }
  .bg-task{
    background-color: rgba(0, 46, 116, 0.2) !important;
  }
  .bg-manage{
    background-color: rgba(222, 12, 21, 0.2) !important;
  }
  .bg-billing{
    background-color: rgba(0, 133, 44, 0.2) !important;
  }
  .bg-invoice{
    background-color: rgba(255, 178, 10, 0.2) !important;
  }
  .text-task{
    color: #002D72 !important;
  }
  .text-manage{
    color: #DE0C15 !important;
  }
  .text-billing{
    color: #00852C !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-invoice{
    color: #FFB20A !important;
  } */

/* .detail-container {

   margin-bottom: 44px;
  }
  .created_name{
    border-radius: 4px;
background-color: rgba(255, 77, 0, 0.10);
display: flex;
align-items: center;
justify-content: center;
  }
  .other-detail {
    margin: 10px;
    margin-bottom: 20px;
  }
  .other-detail-title {
    width: 648px;
    height: 23px;
    font-size: 18px;
    font-family: Roboto;
    line-height: 23.44px;
    font-weight: 500;
    color: #3A3A3A7A;
    margin: 8px;
  }
  .image-wrapper {
    width: 24px;
    height: 24px;
    border: 0.4px;
    color: #002D72;
  }
  .other-detail-text {
    width: 648px;
    height: 25px;
    font-size: 18px;
    font-family: Roboto;
    line-height: 23.25px;
    font-weight: 500;
    color: #272727;
    margin-left: 38px;

  } */
/* .subtasks-container {
    margin-top: 32px;
  }
  .subtasks-title {
    width: 100px;
    height: 28px;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 28.13px;
    margin-bottom: 24px;
  }

  .statusHistory-container {
    width: 688px;
    height: 248px;
    margin-top: 32px;
  }
  .statusHistory-title {
    width: 153px;
    height: 28px;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 28.13px;
    margin-bottom: 24px;
  }
  .modal_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
  } */

/* 
  .modal-container {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 80%;
  }
   .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .remarks_textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  
  .btn_remarks {
    padding: 10px 20px;
    background-color: #002D72;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_remarks:hover {
    background-color: #001F4F;
  }   */



.set_text_gap {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;

}

.label_data {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.details_text {
  color: var(--Neutral-8, #595959);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.info-window {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    max-width: 250px;
    padding: 10px;
  }
  
  .info-window h4 {
    margin-top: 0;
    color: #002D72; 
    font-size: 1.1rem;
  }
  
  .info-window p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .info-window .info-label {
    font-weight: bold;
  }
  
  .info-window .info-value {
    font-style: italic;
    color: #555;
  }
  
  .info-window .divider {
    border-bottom: 1px solid #ddd;
    margin: 10px 0;
  }
  
  .gm-style-iw-chr {
    display: flex;
    overflow: visible;
    height: 1px !important;
}
.marker-position {
  bottom: 6px;
  left: 0;
  position: relative;
}
.locate-map-container{
    width: 100%;
    height: 100%;
}
.legacy-container{
    width: 100%;
    height: 100%;
}
.btn:focus{
    outline: 0px !important;
}
.chip-red{
    padding: 5px 10px;
    display: inline-block;
    background-color: rgba(255, 0, 0, 0.10);
    border-radius: 5px;
}
.chip-green{
    padding: 5px 10px;
    display: inline-block;
    background-color:rgba(77, 186, 0, 0.10);
    border-radius: 5px;
}
.text-green{
    color: #4DBA00;
}



.password-input-wrapper {
    position: relative;
  }
  
  .password-input-wrapper input {
    padding-right: 2.5rem; 
  }
  
  .password-input-wrapper span {
    position: absolute;
    top: 50%;
    right: 0.5rem; 
    transform: translateY(-50%);
    cursor: pointer;
  }






.logo-section{
    height: 95vh;
    display: grid;
    align-items: center;
    /* background-image: url('./mask-logo.png'); */
    background-size: cover;
    
}
.logo-section .border-div img{
    margin: auto;
}
.logo-section .border-div{
    width: 100%;
    height: 40vh;
    display: grid;
    align-items: center;
    border-right: 1px solid #E8E8E8;
}
.forgotPassword-form{
    height: 95vh;
    display: grid;
    align-items: center;
}
.input-wrapper{
    width: 350px;
    margin-left: 0px;
    margin-bottom: 20px;
}
.form label{
    color: #ABABAB;
    margin-bottom: 10px;
}
.form label img{
    height: 20px;
    margin-left: 7px;
    margin-right: 7px;
}
.form input{
    width: 350px;
    height: 50px;
    border: 1px solid #F2F4F7;
    border-radius: 7px;
    display: block;
    background-color: #F2F4F7;
    padding-left: 20px;
}
.form input:focus-visible{
    outline: 1px solid #ccc !important;
}
.form button{
    display: block;
    width: 350px;
    height: 50px;
    border-radius: 7px;
    color: white;
    font-weight: 600;
    border: 1px solid #002D72;
    background-color: #002D72;
}
.form button:disabled{
    opacity: 0.8;
}
.footer{
    width: 100%;
    background-color: 	#002D72;
    height: 5vh;
}
.footer p{
    line-height: 5vh;
    font-size: 1.8vh;
    font-weight: 600;
    color: 	white;
}
.all_boxes {
    display: flex;
    /* gap: 16px; */
}

.first_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #505157;
}

.second_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #87A2C7;
}

.third_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E1A900;
}

.four_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #2ECC71;
}

.five_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #5858D1;
}

.six_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E74C3C;
}

.seven_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E71600;
}

.eight_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #8381FF;
}

.nine_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    margin-bottom: -6px;
    border-radius: 4px;
    background: #9B59B6;
}

.ten_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    padding-right: 0px;
    margin: 6px 6px 0px 6px;
    border-radius: 4px;
    background: #A9A9A9;
}

.eleven_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #A9A9A9;
}

.tweleve_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #07A74A;
}

.thirteen_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #42526E;
}

.inp {
    border: none;
    border-bottom: 1px solid #1890ff;
    padding: 5px 10px;
    outline: none;
}

[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: text-indent 0.4s 0.4s ease;
    transition: text-indent 0.4s 0.4s ease;
    text-indent: -100%;
    opacity: 1;
}

.key {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 16px;
}

.value {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 10px;
}

.pie-chart {
    height: 340px;
    width: 340px;
    margin-left: 6px;
}
.PieChart {
    font-family: sans-serif;
    text-align: center;
  }
  
.summary-main {
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.top-header {
    margin-top: -16px;
    width: 75%;
    display: flex;
    position: fixed;
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1000;
    grid-gap: 16px;
    gap: 16px;
}

.summary-title {
    height: 32px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #272727;

}

.download-button {
    width: 32px;
    height: 32px;
    box-shadow: 1px #0000000B;
    background-color: #FFFFFF;
}

.close-button {
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-left: auto;
    background: #FFFFFF;
}

.pdf-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-container {
    width: 720px;
    height: 70px;
}

.logo-wraper {
    width: 720px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fieldforce-logo {
    width: 146px;
    height: 38px;
}

.biocare-logo {
    width: 138px;
    height: 36px;
    margin-left: auto;
}

.line {
    width: 730px;
    height: 1.3px;
    background-color: #000000;
}

.title1 {
    /* width: 720px; */
    /* height: 39px; */
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    color: #000000;
}

.chart1 {
    width: 760px;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.chart2 {
    width: 760px;
    height: 400px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.chart3 {
    margin-top: 36px;
    width: 760px;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.table1 {
    margin-top: 16px;
    width: 760px;
    margin-left: 40px;
}

.form-footer {
    width: 740px;
    height: 19px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.text-wraper {
    width: 212px;
    height: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 10px;
    text-align: left;
    color: #000000;
}

.date-wraper {
    width: 98px;
    height: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: right;
    color: #000000;
    margin-right: 10px;
}

.planner-container {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.planner-header {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.header-navigation {
    grid-gap: 12px;
    gap: 12px;
    display: flex;
    align-items: center;
}

.chevron-right-icon {
    width: 18px;
    height: 18px;
}

.calender-container {
    width: 1022px;
    height: 790px;
    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    border-radius: var(--Corner400);
    box-sizing: border-box;
    background-color: #FFFFFF;
}
@font-face {
    font-family: 'SFProDisplayMedium';
    src: url(/static/media/SFProDisplay-Medium.c9c3d293.ttf) format('truetype');
}

.calender-container {
    width: 100%;
    height: auto;
    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    border-radius: var(--Corner400);
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.calendar-header {
    width: 100%;
    height: 62px;
    padding: var(--Spacing200) 0 var(--Spacing200) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button-container {
    /* min-width: 161px; */
    min-height: 30px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 4px;
    background-color: #F3F4F6;
}

.calendar-list-button-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 4px;
    background-color: #F3F4F6;
    /* gap: 4px; */
}

.custom-button-icon {
    display: flex;
    height: 22px;
    width: 24px;
    padding: 0px 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

/* Active state */
.custom-button-icon.active {
    background-color: #FFFFFF;
    border-radius: 6px;
}

.custom-button-icon img {
    width: 16px;
    height: 16px;
}

.custom-button {
    min-height: 22px;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

/* Active state */
.custom-button.active {
    background-color: #FFFFFF;
    border-radius: 6px;
}

.month-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 24px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.draft {
    min-width: 53px;
    min-height: 26px;
    padding: 3px 8px;
    border-radius: 4px;
    background: #7777771A;
}

.draft.draft-status {
    background: #7777771A;
}

.draft.pending-status {
    background: #D977061A;
}

.draft.rejected-status {
    background: #E10E0E1A;
}

.draft.approved-status {
    background: #1A82451A;
}

.draft.default-status {
    background: #7777771A;
    /* Fallback */
}

.submit-plan {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 28px;
    padding: 4px 12px;
    border-radius: 6px;
    background: #002D72;
    transition: background 0.3s ease;
}

.submit-plan:hover {
    background: #001136;
}


/* new calendar css */
.calendar-weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: left;
    grid-gap: 10px;
    gap: 10px;
}

.calendar-day-name {
    padding: 10px;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #E7EEF8;
    border-collapse: collapse;
}

.calendar-day {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1.4;
    padding: 10px;
    grid-gap: 4px;
    gap: 4px;
    cursor: pointer;
    border: 1px solid #E7EEF8;

    /* Ensures no overflow changes the size */
    overflow: hidden;
    box-sizing: border-box;
}

.event-container {
    max-width: 100%;
    max-height: 60%;
    /* background-color: rgba(0, 123, 255, 0.1); */
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    grid-gap: 4px;
    gap: 4px;
}

.event-detail {
    max-width: -webkit-min-content;
    max-width: min-content;
    min-height: 20px;
    padding: 0px 4px 0px 4px;
    /* gap: 10px; */
    border-radius: 4px;
    background-color: #0085FF1A;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    color: #0085FF;
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;

    /* Truncate overflowing text */
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.extra-count {
    color: #384153;
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

/* week calender */
.week-calendar-day-name {
    display: flex;
    flex-direction: column;
    padding: 10px;
    grid-gap: 2px;
    gap: 2px;
}

/* new */

.week-calendar-day-name {
    display: flex;
    flex-direction: column;
    padding: 10px;
    grid-gap: 2px;
    gap: 2px;
}

/* Week hour row to display time and days */
.calendar-week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* border: 1px solid #E7EEF8; */
    border-collapse: collapse;
}

.week-hour-row {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr);
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.calendar-week-weekdays {
    margin-left: 55px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: left;
    grid-gap: 8px;
    gap: 8px;
}

/* day css */

.day-header {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 45px;
}

.day-hour-row {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr);
    align-items: center;

}

.day-hour {
    display: flex;
    flex-direction: column;
    height: 56px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #E7EEF8;
}

/* new css */
.otherbutton-container {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    justify-content: flex-end;
    width: 458px;
    height: 30px;
    align-items: center;
}

.myplan-employeeselect-container {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    width: 253px;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
}

.employee-select {
    width: 150px;

}

.my-plan-button {
    width: 87px;
    height: 30px;
    padding: 4px 10px;
    grid-gap: 4px;
    gap: 4px;
    border-radius: 44px;
    border: 1px solid #DFE4EA;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F4F6;
}

.my-plan-button-active {
    background-color: #E2E7FF;
    border-color: #004BB4;
}

.reject-button {
    min-width: 81px;
    height: 30px;
    padding: 4px 12px;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #F23030;
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.approve-button {
    min-width: 92px;
    height: 30px;
    padding: 4px 12px;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 6px;
    background-color: #046C4E;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
/* Backdrop for the modal */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

/* Modal container */
.modal-container {
    position: relative;
    width: 400px;
    min-height: 283px;
    padding: 24px;
    box-shadow: 0px 5px 12px 0px #0000001A;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 20px;
    background-color: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 1000;
    left: 10%;
}

.content-container {
    width: 352px;
    min-height: 175px;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.icon-container {
    width: 60px;
    height: 60px;
    border-radius: 37px;
    background: #F3F4F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container img {
    width: 24px;
    height: 24px;
}

.text-container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
}

.button-contiainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 18px;
    gap: 18px;
}

.cancel-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 12px;
    border: 1px solid #DFE4EA;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primary-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 6px;
    background: #002D72;
    display: flex;
    align-items: center;
    justify-content: center;
}

.danger-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 6px;
    background: #C81E1E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.city-modal-container {
    position: relative;
    width: 400px;
    min-height: 198px;
    padding: 24px;
    box-shadow: 0px 4px 13px 0px #0000001A;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 1000;
}

.city-content-container {
    width: 352px;
    min-height: 122px;
    grid-gap: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.citymodal-header {
    display: flex;
    justify-content: space-between;
}
.citymodal-header button {
    background-color: #FFFFFF;
}

.selectcity-row {
    display: flex;
    flex-direction: column;
    min-height: 56px;
    grid-gap: 12px;
    gap: 12px;
}

.cityicon-label {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.cityicon-label img {
    width: 20px;
    height: 20px;
}

.city-button-contiainer {
    display: flex;
    align-items: center;
    justify-content: right;
    grid-gap: 18px;
    gap: 18px;
}
.city-select-container {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}
.error-text {
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
}
.divider-option {
    pointer-events: none;
    text-align: center;
    font-size: 14px;
    color: #ccc;
    padding: 5px 0;
    display: block;
}
@font-face {
    font-family: 'SFProDisplayRegular';
    src: url(/static/media/SFProDisplay-Regular.1e1c0e29.ttf) format('truetype');
}

.custom-normalselect {
    width: 100%;
    height: 100%;
    /* max-height: 100px; */
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    background-color: #F3F4F6;
    color: #384153;
    font-size: 14px;
    font-family: "SFProDisplayRegular";
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.custom-normalselect:disabled,
.custom-normalselect.custom-disabled {
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;
    color: #6C757D;
    cursor: default;
    opacity: 0.7;
}
@font-face {
    font-family: 'SFProDisplayRegular';
    src: url(/static/media/SFProDisplay-Regular.1e1c0e29.ttf) format('truetype');
}

.custom-subordinate-select-container {
    position: relative;
    width: 160px;
    cursor: pointer;
    height: 30px;
}

.custom-subordinate-select {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DFE4EA;
    background-color: #F3F4F6;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #384153;
    font-family: "SFProDisplayRegular";
}

.main-label-container {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-subordinate-select-options {
    top: 120%;
    right: 0;
    left: auto;
    width: 150%;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    background-color: #FFFFFF;
    z-index: 10;
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
}

.custom-subordinate-select-option {
    padding: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "SFProDisplayRegular";
    color: #384153;
}

.custom-subordinate-select-option:hover {
    background-color: #f0f0f0;
}

.custom-disabled {
    opacity: 0.5;
    pointer-events: none;
}
.accordian-view-container {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.accordian-view-header {
    display: flex;
    height: 48px;
    width: 100%;
    border-radius: 16px;
    background-color: #DFE4EA;
}

.header-col {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 12px 25px;
}

.accordian-nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
}
.accordian-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
    padding: 24px;
    min-height: 80px;
    overflow: hidden;
    /* Prevents overflowing content during animation */
    transition: height 0.3s ease-in-out;
}



.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.accordian-button {
    width: 32px;
    height: 32px;
    border-radius: 88px;
    background: #F3F4F6;
}

.accordian-row {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    height: 22px;
    align-items: center;
}


.accordian-content-wrapper {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    /* Smooth height changes */
}

.accordian-content {
    padding: 2px 0px;
}

.accordian-content hr {
    border-color: #DFE4EA;
}
.planner-table-container {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
    overflow: hidden;
}

.planner-table {
    width: 100%;
}

.header-colmn {
    height: 48px;
    background-color: #E5E7EB;
    padding: 12px 16px;
}

.colmn-container {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.planner-table tbody td {
    height: 60px;
    padding: 20px 16px;
    vertical-align: middle;
}
.planner-table tbody tr {
    border-bottom: 1px solid #DFE4EA;
}
.view-drawer-container {
    position: fixed;
    top: 0;
    right: -320px;
    /* Initially hidden off-screen */
    width: 300px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 13px 4px #0000001F;
    transition: right 0.3s ease;
    z-index: 99999;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.view-drawer-container.open {
    right: 0;
}

.view-drawer-main {
    width: 300px;
    padding: 24px;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Drawer header */
.view-drawer-header {
    display: flex;
    justify-content: space-between;
    height: 26px;
    padding: 24px;
}


/* Backdrop styling */
.view-drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    z-index: 99998;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.view-drawer-backdrop.open {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: auto;
}

.close-icon {
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
}
.event-card {
    width: 252px;
    min-height: 150px;
    grid-gap: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.eventcard-content-container {
    height: 22px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.eventcard-content-container img {
    width: 16px;
    height: 16px;
}

.eventcard-content-container hr {
    border: 1px solid #DFE4EA;
    height: 2px;
}
.add-drawer-container {
    position: fixed;
    top: 0;
    right: -330px;
    /* Initially hidden off-screen */
    width: 310px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 13px 4px #0000001F;
    transition: right 0.3s ease;
    z-index: 99999;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.add-drawer-container.open {
    right: 0;
}

/* Drawer header */
.add-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    padding: 24px;
}

.add-drawer-main {
    width: 310px;
    padding: 24px;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 100%;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
}


/* Backdrop styling */
.add-drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    z-index: 99998;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.add-drawer-backdrop.open {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: auto;
}

.close-icon-button {
    background-color: #FFFFFF;
}

.close-icon-button img {
    width: 18px;
    height: 18px;
}

.delete-icon-button {
    background-color: #FFFFFF;

}

.delete-icon-button img {

    width: 18px;
    height: 20px;
}

.save-button {
    width: 80px;
    min-height: 22px;
    display: flex;
    border-radius: 6px;
    grid-gap: 8px;
    gap: 8px;
    padding: 4px 12px;
    background-color: #002D72;
    align-items: center;
}

.save-button:disabled,
.save-button.disabled {
    background-color: #B0B0B0;
}

.footer-button-container {
    width: 300px;
    height: 74px;
    /* border-top: 1px solid; */
    padding: 24px;
    grid-gap: 18px;
    gap: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 10;
}

.cancel-button {
    display: flex;
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 8px 12px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.delete-button {
    display: flex;
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 12px;
    background-color: #C81E1E;
    align-items: center;
    justify-content: center;
}

.add-another-button {
    display: flex;
    width: 252px;
    min-height: 28px;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    padding: 4px 12px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    grid-gap: 8px;
    gap: 8px;
}
.add-eventcard {
    width: 260px;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #DFE4EA;
    position: relative;
}

.add-eventcard-row {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    min-height: 56px;
    grid-gap: 6px;
    gap: 6px;
}

.icon-label {
    min-width: 82px;
    min-height: 22px;
    grid-gap: 8px;
    gap: 8px;
    display: flex;
    align-items: center;
}

.icon-label img {
    width: 16px;
    height: 16px;
}

.visit-dropdown-container {
    display: flex;
    min-height: 30px;
    justify-content: space-between;
}

.visit-dropdown-container img {
    width: 7px;
}

.event-checkbox {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    border: 1px solid #DFE4EA;
    border-radius: 4px;
    outline: none;
    background-color: #FFFFFF;
    display: inline-block;
}

.event-checkbox:checked {
    background-color: #006CFA;
    border-color: #006CFA;
}

/* Add the white tick for the checked state */
.event-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
}

.select-container {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.select-container p {
    color: red;
    font-size: 10px;
    font-weight: 400;
}

.head-office-row {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}
.headoffice-checkbox {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    border: 1px solid #DFE4EA;
    cursor: pointer;
}
@font-face {
    font-family: 'SFProDisplayRegular';
    src: url(/static/media/SFProDisplay-Regular.1e1c0e29.ttf) format('truetype');
}

/* Custom Select Container */
.custom-timeselect-container {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    position: relative;
}

/* Custom Select Styles */
.custom-timeselect {
    width: 100%;
    height: 100%;
    /* max-height: 100px; */
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    background-color: #F3F4F6;
    color: #384153;
    font-size: 14px;
    font-family: "SFProDisplayRegular";
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
/* VisitProfile.css */
.main-container {
    margin-top: 20px;
}

.visit-profile-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.content-header {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}
.header-navigation {
    grid-gap: 12px;
    gap: 12px;
    display: flex;
    align-items: center;
}
.content-wrapper {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    min-height: 400px;
}
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.dynamic-table-container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.dynamic-table-container table {
    width: 100%;
    border-collapse: collapse;

}
.header-with-icon {
    display: flex;
    align-items: center; 
    grid-gap: 8px; 
    gap: 8px; 
    justify-content: flex-start; 
}

.dynamic-table-container th {
    text-align: left;
    padding: 12px 15px;
    background-color: #E5E7EB;
    color: #333;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e0; 
}

.dynamic-table-container th:last-child,
.dynamic-table-container td:last-child {
    text-align: center;
}

.dynamic-table-container td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e0e0e0;
    color: #666;
}

.custom-pagination {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
}

.custom-pagination span {
    margin: 0 5px;
}

.custom-pagination button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
}

.custom-pagination button:disabled {
    cursor: not-allowed;
    color: #ccc;
}
.custom-pagination  button svg {
    width: 16px;
    height: 16px;
}

.add-visit-profile-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.add-content-wrapper {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15px;
    min-height: 300px;
    box-sizing: border-box;
}
.custom-button-style {
    width: 112px;
    height: 28px;
    padding: 4px 12px;
    grid-gap: var(--2);
    gap: var(--2);
    border-radius: 6px 0px 0px 0px;
    border: 1px solid var(--MIsc-Stroke, #DFE4EA);
    opacity: 0;
}

.add-visit-profile-row-container {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
    margin: 20px 0;
}
.add-visit-profile-rounded-fields-container {
    background-color: white;
    width: 90%;
    padding: 16px;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid white; /* Change border color to white */
    box-shadow: 0px 1px 3px 0px #3232471A, 0px 0px 1px 0px #0C1A4B33; /* Apply box-shadow */
}

.maindiv{
    display: flex;
    align-items: center;
}
.add-visit-profile-error-text {
    color: red;
    font-size: 12px;
}

.add-visit-profile-form-actions {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0;
}
.add-visit-profile-form-actions-left {
    display: flex;
    justify-content: flex-start;  
    grid-gap: 10px;  
    gap: 10px;
    padding: 10px 0;
  }
.removeIcon{
    width: 10%;
    display: flex;
   padding: 25px;
}
.VisitProfileform{
    margin-left: 6%;
    margin-right: 6%;
}
/* VisitlocationsRow.css */

.visit-profile-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.content-header {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}
.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
    width: 400px;
    height: 260px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 24px;
    gap: 24px;
    opacity: 1;
    z-index: 9999;
}

.text-container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
}
.modal-content {
    text-align: center;
    padding: 16px;
}
.modal-img{
    display: flex;
    justify-content: center;
}
.modal-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
   
}

.modal-buttons {
    display: flex;
    justify-content: center;
   grid-gap: 12px;
   gap: 12px;
   margin-top: 20px;
}

.dynamicmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10010;
}

.dynamicmodal-form-group textarea:focus,
.dynamicmodal-form-group input:focus {
  outline: none;
  border: none;
}

.dynamicmodal-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.dynamicmodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dynamicmodal-header-left .icon {
  width: 48px;
  height: 48px;
  padding: 12px ;
  border-radius: 10px;
  border: 1px solid #E9EAEB;
 background: #FFFFFF;
}

.dynamicmodal-header-left {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.dynamicmodal-header-left .text p:first-child {
  font-weight: bold;
}

.dynamicmodal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.dynamicmodal-form-group {
  display: flex;
  flex-direction: column;
}

.dynamicmodal-footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.dynamicmodal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

