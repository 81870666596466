.address-cell {
    max-width: 25%;
    word-wrap: break-word;
  }
  .set_label{
    display: flex;
    align-items: flex-start;
  }

  .toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
      
  }
  .address-cell {
    max-width: 25%;
    word-wrap: break-word;
  }
  .set_label{
    display: flex;
    align-items: flex-start;
  }
  .toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
  }
  .document-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }
  .add_document {
    width: 162px;
    height: 114px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    gap: 8px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 10px;
    position: relative; 
    overflow: hidden;
  }
  .add_document:hover {
    background-color: #002D72;
  }
  .trash-icon {
    position: absolute;
    display: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    stroke: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .add_document:hover .trash-icon {
    display: block;
  }
.no-files-text {
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
margin-top: 5px;
}
