.icon-button {
    width: 40px;
    height: 40px;
    border-radius: 88px;
    background: var(--Gray-Colors-Gray-2, #F3F4F6); /* Background color */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space inside for potential child elements */
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color change */
  }
  
  .icon-button:hover {
    background-color: #e2e3e5; /* Slightly darker on hover for feedback */
  }
  
  .icon-button:active {
    background-color: #d1d3d5; /* Even darker on active click */
  }
  