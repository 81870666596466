@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('../../assets/appleFont/SFProDisplay-Regular.ttf') format('truetype');
}

.custom-normalselect {
    width: 100%;
    height: 100%;
    /* max-height: 100px; */
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    background-color: #F3F4F6;
    color: #384153;
    font-size: 14px;
    font-family: "SFProDisplayRegular";
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.custom-normalselect:disabled,
.custom-normalselect.custom-disabled {
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;
    color: #6C757D;
    cursor: default;
    opacity: 0.7;
}