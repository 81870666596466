.chip-red{
    padding: 5px 10px;
    display: inline-block;
    background-color: rgba(255, 0, 0, 0.10);
    border-radius: 5px;
}
.chip-green{
    padding: 5px 10px;
    display: inline-block;
    background-color:rgba(77, 186, 0, 0.10);
    border-radius: 5px;
}
.text-green{
    color: #4DBA00;
}
