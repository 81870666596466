.avatar {
  border: 0.3rem solid rgba(#fff, 0.3);
  margin-top: 0px;
  margin-bottom: 1rem;
  max-width: 7rem;
}

.employee-wrapper {
  display: flex;
}

.employee-wrapper .card {
  width: 100%;
}

.avatar-wrapper {
  flex-basis: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

}

.avatar-set {
  background: green;
  border-radius: 100%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.avatar-wrapper div p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}

.form-group .required.form-control-label:after {
  content: "*";
  color: red;
  font-weight: 900;
}

.text-red {
  color: red !important;
}

.hidden-input {
  color: transparent;
  text-shadow: 0 0 0 #000;
  /* Make text shadow the same color as the background */
  background-color: transparent;
  /* Ensure the background is transparent */
  border: none;
  /* Remove border if necessary */
  outline: none;
  /* Remove focus outline if necessary */
}

.employees_set {
  color: rgba(58, 58, 58, 0.48);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.personal {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}


.avatar-dp-set {
  background: green;
  border-radius: 100%;
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
}

.avatar-dp-set p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 55px;
  margin-bottom: 0px;
}

.person_name {
  color: var(--Light-Theme-Heading-Text, #151924);
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 10px;
}

.designation_data {
  color: var(--grey-500, var(--grey-500, #505157));

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.details_text {
  color: var(--Neutral-8, #595959);


  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.set_text_gap {
  display: flex;
  flex-direction: column;
  gap: 16px;

}

.label_data {
  color: #000;

  /* Body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.react-datepicker-wrapper {
  width: 100%;
}