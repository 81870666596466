.manage_input{
    width: 100%;
    height: 80%;
    padding-left: 10px;
    color: rgba(58, 58, 58, 0.48);
    border-radius: 2px;
    background-color: #F5F5F5 ;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.heading_role{
    color: var(--text-primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: bold;
line-height: 24px; 
letter-spacing: 0.17px;
}
.table_text{
    color: #272727;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 468px !important;
    height: 54px !important;
    border-radius: 8px !important;
    background: var(--Foundation-Light-White, #FFF) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04) !important;

} */
/* .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    width: 94% !important;
}
.css-1m66l2e {
    width: 100% !important;
}
.css-1siprgr{
    width: 100% !important;
}
.css-1v4ccyo{
    width: 468px !important;
} */
.login-button{
    display: flex !important;
}