.all_boxes {
    display: flex;
    /* gap: 16px; */
}

.first_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #505157;
}

.second_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #87A2C7;
}

.third_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E1A900;
}

.four_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #2ECC71;
}

.five_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #5858D1;
}

.six_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E74C3C;
}

.seven_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #E71600;
}

.eight_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #8381FF;
}

.nine_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    margin-bottom: -6px;
    border-radius: 4px;
    background: #9B59B6;
}

.ten_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    padding-right: 0px;
    margin: 6px 6px 0px 6px;
    border-radius: 4px;
    background: #A9A9A9;
}

.eleven_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #A9A9A9;
}

.tweleve_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #07A74A;
}

.thirteen_box {
    width: 180px;
    height: 100px;
    padding: 16px;
    margin: 6px;
    border-radius: 4px;
    background: #42526E;
}

.inp {
    border: none;
    border-bottom: 1px solid #1890ff;
    padding: 5px 10px;
    outline: none;
}

[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease;
    text-indent: -100%;
    opacity: 1;
}

.key {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 16px;
}

.value {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 10px;
}

.pie-chart {
    height: 340px;
    width: 340px;
    margin-left: 6px;
}