@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('../../assets/appleFont/SFProDisplay-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProDisplayMedium';
    src: url('../../assets/appleFont/SFProDisplay-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProDisplaySemibold';
    src: url('../../assets/appleFont/SFProDisplay-Semibold.ttf') format('truetype');
}

.body-extra-small-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
}

.body-extra-small-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
}

.body-small-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
}

.body-medium-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    /* text-underline-position: from-font; */
}

.body-large-medium {
    font-family: 'SFProDisplayMedium';
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
}

.body-small-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
}

.body-medium-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 16px;
    line-height: 24px;
    text-underline-position: from-font;
}


.heading6-regular {
    font-family: 'SFProDisplayRegular';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    padding: 6px;
    text-underline-position: from-font;
}

.heading6-semibold {
    font-family: 'SFProDisplaySemibold';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
}