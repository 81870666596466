/* Backdrop for the modal */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

/* Modal container */
.modal-container {
    position: relative;
    width: 400px;
    min-height: 283px;
    padding: 24px;
    box-shadow: 0px 5px 12px 0px #0000001A;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px;
    background-color: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 1000;
    left: 10%;
}

.content-container {
    width: 352px;
    min-height: 175px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.icon-container {
    width: 60px;
    height: 60px;
    border-radius: 37px;
    background: #F3F4F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container img {
    width: 24px;
    height: 24px;
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
}

.button-contiainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
}

.cancel-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 12px;
    border: 1px solid #DFE4EA;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primary-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 6px;
    background: #002D72;
    display: flex;
    align-items: center;
    justify-content: center;
}

.danger-button {
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 6px;
    background: #C81E1E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.city-modal-container {
    position: relative;
    width: 400px;
    min-height: 198px;
    padding: 24px;
    box-shadow: 0px 4px 13px 0px #0000001A;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 1000;
}

.city-content-container {
    width: 352px;
    min-height: 122px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.citymodal-header {
    display: flex;
    justify-content: space-between;
}
.citymodal-header button {
    background-color: #FFFFFF;
}

.selectcity-row {
    display: flex;
    flex-direction: column;
    min-height: 56px;
    gap: 12px;
}

.cityicon-label {
    display: flex;
    gap: 8px;
}

.cityicon-label img {
    width: 20px;
    height: 20px;
}

.city-button-contiainer {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 18px;
}
.city-select-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.error-text {
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
}
.divider-option {
    pointer-events: none;
    text-align: center;
    font-size: 14px;
    color: #ccc;
    padding: 5px 0;
    display: block;
}