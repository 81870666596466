.summary-main {
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.top-header {
    margin-top: -16px;
    width: 75%;
    display: flex;
    position: fixed;
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1000;
    gap: 16px;
}

.summary-title {
    height: 32px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #272727;

}

.download-button {
    width: 32px;
    height: 32px;
    box-shadow: 1px #0000000B;
    background-color: #FFFFFF;
}

.close-button {
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-left: auto;
    background: #FFFFFF;
}

.pdf-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-container {
    width: 720px;
    height: 70px;
}

.logo-wraper {
    width: 720px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fieldforce-logo {
    width: 146px;
    height: 38px;
}

.biocare-logo {
    width: 138px;
    height: 36px;
    margin-left: auto;
}

.line {
    width: 730px;
    height: 1.3px;
    background-color: #000000;
}

.title1 {
    /* width: 720px; */
    /* height: 39px; */
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    color: #000000;
}

.chart1 {
    width: 760px;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.chart2 {
    width: 760px;
    height: 400px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.chart3 {
    margin-top: 36px;
    width: 760px;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.table1 {
    margin-top: 16px;
    width: 760px;
    margin-left: 40px;
}

.form-footer {
    width: 740px;
    height: 19px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.text-wraper {
    width: 212px;
    height: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 10px;
    text-align: left;
    color: #000000;
}

.date-wraper {
    width: 98px;
    height: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: right;
    color: #000000;
    margin-right: 10px;
}