.add-drawer-container {
    position: fixed;
    top: 0;
    right: -330px;
    /* Initially hidden off-screen */
    width: 310px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 13px 4px #0000001F;
    transition: right 0.3s ease;
    z-index: 99999;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.add-drawer-container.open {
    right: 0;
}

/* Drawer header */
.add-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    padding: 24px;
}

.add-drawer-main {
    width: 310px;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}


/* Backdrop styling */
.add-drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    z-index: 99998;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.add-drawer-backdrop.open {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: auto;
}

.close-icon-button {
    background-color: #FFFFFF;
}

.close-icon-button img {
    width: 18px;
    height: 18px;
}

.delete-icon-button {
    background-color: #FFFFFF;

}

.delete-icon-button img {

    width: 18px;
    height: 20px;
}

.save-button {
    width: 80px;
    min-height: 22px;
    display: flex;
    border-radius: 6px;
    gap: 8px;
    padding: 4px 12px;
    background-color: #002D72;
    align-items: center;
}

.save-button:disabled,
.save-button.disabled {
    background-color: #B0B0B0;
}

.footer-button-container {
    width: 300px;
    height: 74px;
    /* border-top: 1px solid; */
    padding: 24px;
    gap: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 10;
}

.cancel-button {
    display: flex;
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 8px 12px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.delete-button {
    display: flex;
    width: 80px;
    min-height: 36px;
    border-radius: 6px;
    padding: 8px 12px;
    background-color: #C81E1E;
    align-items: center;
    justify-content: center;
}

.add-another-button {
    display: flex;
    width: 252px;
    min-height: 28px;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    padding: 4px 12px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    gap: 8px;
}