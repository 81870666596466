
.password-input-wrapper {
    position: relative;
  }
  
  .password-input-wrapper input {
    padding-right: 2.5rem; 
  }
  
  .password-input-wrapper span {
    position: absolute;
    top: 50%;
    right: 0.5rem; 
    transform: translateY(-50%);
    cursor: pointer;
  }






.logo-section{
    height: 95vh;
    display: grid;
    align-items: center;
    /* background-image: url('./mask-logo.png'); */
    background-size: cover;
    
}
.logo-section .border-div img{
    margin: auto;
}
.logo-section .border-div{
    width: 100%;
    height: 40vh;
    display: grid;
    align-items: center;
    border-right: 1px solid #E8E8E8;
}
.forgotPassword-form{
    height: 95vh;
    display: grid;
    align-items: center;
}
.input-wrapper{
    width: 350px;
    margin-left: 0px;
    margin-bottom: 20px;
}
.form label{
    color: #ABABAB;
    margin-bottom: 10px;
}
.form label img{
    height: 20px;
    margin-left: 7px;
    margin-right: 7px;
}
.form input{
    width: 350px;
    height: 50px;
    border: 1px solid #F2F4F7;
    border-radius: 7px;
    display: block;
    background-color: #F2F4F7;
    padding-left: 20px;
}
.form input:focus-visible{
    outline: 1px solid #ccc !important;
}
.form button{
    display: block;
    width: 350px;
    height: 50px;
    border-radius: 7px;
    color: white;
    font-weight: 600;
    border: 1px solid #002D72;
    background-color: #002D72;
}
.form button:disabled{
    opacity: 0.8;
}
.footer{
    width: 100%;
    background-color: 	#002D72;
    height: 5vh;
}
.footer p{
    line-height: 5vh;
    font-size: 1.8vh;
    font-weight: 600;
    color: 	white;
}