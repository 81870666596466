.add-eventcard {
    width: 260px;
    gap: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #DFE4EA;
    position: relative;
}

.add-eventcard-row {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    min-height: 56px;
    gap: 6px;
}

.icon-label {
    min-width: 82px;
    min-height: 22px;
    gap: 8px;
    display: flex;
    align-items: center;
}

.icon-label img {
    width: 16px;
    height: 16px;
}

.visit-dropdown-container {
    display: flex;
    min-height: 30px;
    justify-content: space-between;
}

.visit-dropdown-container img {
    width: 7px;
}

.event-checkbox {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    border: 1px solid #DFE4EA;
    border-radius: 4px;
    outline: none;
    background-color: #FFFFFF;
    display: inline-block;
}

.event-checkbox:checked {
    background-color: #006CFA;
    border-color: #006CFA;
}

/* Add the white tick for the checked state */
.event-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
}

.select-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.select-container p {
    color: red;
    font-size: 10px;
    font-weight: 400;
}

.head-office-row {
    display: flex;
    gap: 8px;
    align-items: center;
}
.headoffice-checkbox {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    border: 1px solid #DFE4EA;
    cursor: pointer;
}