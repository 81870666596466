.form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 16px;
}

.form-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.container-fluid {
    width: 100vw;
    overflow-x: hidden;
}

.logo {
    max-width: 229px;
    height: 72px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 100%;
    height: auto;
}

.login-form {
    width: 100%;
}

.login-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
}

.fields-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.email,
.password,
.check-box {
    width: 100%;
}

.login-button {
    width: 100%;
    height: 48px;
}

.login-button button {
    width: 100%;
    height: 48px;
    background-color: #002D72;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button button:hover {
    background-color: #004BB4;
}

.text-center a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #4E4E4E;
}

.footer-1 {
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 60px;
}

.footer-1 p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: var(--grey-700, #131316);
}

.main-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    height: 562px;
    width: 525px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-right {
    width: 525px;
    height: 334px;
    gap: 24px;
    border-radius: 10px;
    background: #F7FAFC;
    position: relative;
    box-sizing: border-box;
}

.title {
    max-width: 255px;
    top: 45px;
    left: 44px;
    gap: 16px;
    position: absolute;
}

.title p {
    font-family: Roboto;
    font-size: 34px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #002D72;
    margin-bottom: 16px;
}

.sub-title {
    max-width: 255px;
    top: 144px;
    left: 44px;
    gap: 0px;
}

.sub-title span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #718096;
    display: block;
}

.mobile-image {
    width: 282px;
    height: 209px;
    top: 134px;
    left: 318px;
    gap: 0px;
}

.bottom-right {
    width: 524px;
    height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title-bottom {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-bottom p {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #F7FAFC;
    margin: 0;
}

.sub-title-bottom {
    width: 90%;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-title-bottom span {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.7px;
    text-align: center;
    color: #CFD9E0;
    margin: 0;
}

@media (max-width: 768px) {
    .container-fluid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        overflow-x: hidden;
    }

    .row {
        flex-direction: column;
    }

    .col-6 {
        width: 100%;
    }

    .col-lg-6:nth-child(2),
    .col-md-6:nth-child(2),
    .col-sm-12:nth-child(2) {
        display: none;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding: 16px;
    }

    .form-container {
        width: 100%;
        max-width: 400px; 
    }

    .login-fields {
        width: 100%;
    }

    .fields-container {
        width: 100%;
    }

    .login-button button {
        width: 100%;
    }

    .footer-1 {
        margin-top: 16px;
        text-align: center;
    }

    .login-form {
        margin-bottom: 24px;
    }

    .download-button {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }

    .text-center {
        margin-top: 16px;
    }
    .download-app-button {
        display: block !important;
    }
}

@media (min-width: 769px) {
    .download-app-button {
        display: none !important; 
    }
}
