.heading_role{
    color: var(--text-primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;

/* components/table-header */
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: bold;
line-height: 24px; /* 171.429% */
letter-spacing: 0.17px;
}
.toggle_expand{
    position: absolute;
    right: 0px;
    bottom: 0;
      font-size: 20px;
      cursor: pointer;
      
  }