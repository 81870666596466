.Tabular {
  font-family: sans-serif;
  text-align: center;
}

.main-header-table {
  height: 56px;
  border-bottom: 1px solid #0000001F;
  background: #FFFFFF;

}

.main-header-table-td {
  height: 56px;
  padding: 16px 0px 0px 0px;
  font-family: Roboto;
  font-size: 14px;

  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
  font-weight: bold;

}

.main-header-table-td-title {
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
  font-weight: bold;
}

.main-table-row {
  height: 72px;
  border: 0px 0px 1px 0px;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 8px;
  margin: 16px;
  gap: 16px;
  background: #FFFFFF;
  padding: 0px;
}

.main-table-employee {
  height: 72px;

}

.main-table-td {
  height: 52px;
}

.main-header-table-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #000000DE;
}

.expansion-table-header {
  height: 56px;
  border: 0px 0px 1px 0px;
  background: #E6F4FF;
  border-bottom: 1px solid #E6F4FF
}

.expansion-table-header-td {
  height: 56px;
  padding: 16px 0px 0px 0px;

}

.expansion-table-header-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
}

.expansion-table-row {
  height: 54px;
  border: 0px 0px 1px 0px;
  border-bottom: 1px solid #3A3A3A7A
}

.expansion-table-row-td {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}

.expansion-table-row-td-action {
  max-width: 54px;
  height: 54px;
  padding: 8px 16px 8px 16px;
  margin: 8px;
}

.expansion-table-row-td-priority {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}

.expansion-table-row-td-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
}

.task-status {
  height: 24px;
  padding: 6px, 8px, 6px, 8px;
  border-radius: 4px;
  background: #7E7E7E1A;

}

.task-priority {
  height: 24px;
  padding: 6px, 8px, 6px, 8px;
  border-radius: 4px;
  background: #FF00001A;
}

.table-container {
  overflow-x: auto;
}

.expansion-table {
  width: 100%;
}

.expansion-table-row-td-status {
  height: 54px;
  padding: 8px, 16px, 8px, 16px;
  margin: 8px;
}