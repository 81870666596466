@font-face {
    font-family: 'SFProDisplayBold';
    src: url('../../assets/appleFont/SFProDisplay-Bold.ttf') format('truetype');
    /* font-weight: normal; */
    /* font-style: normal; */
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.sidebar-wrapper ul li a div img,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;

}


/*----------------page-wrapper----------------*/

.page-wrapper {
    height: 100vh;
}

.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}

.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}

/*----------------toggeled sidebar----------------*/
.task-drawer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.manage-drawer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.submenu_headings {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
}


.sidebar-submenu {
    display: none;
    padding-left: 20px;
}

.sidebar-dropdown.active .sidebar-submenu {
    display: block;
}

.page-wrapper.toggled .sidebar-wrapper {
    width: 260px;
    left: 0px;
}

.sidebar-menu-title {
    width: auto;
}

@media screen and (min-width: 738px) {

    /* Case 1: If .page-wrapper has .toggled, padding-left should be 280px */
    .page-wrapper.toggled .page-content {
        padding-left: 270px;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
    }

    /* Case 2: If .page-wrapper has .subitemclicked and does NOT have .toggled, padding-left should be 280px */
    .page-wrapper.subitemclicked:not(.toggled) .page-content {
        padding-left: 330px;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
    }

    /* Case 3: If .page-wrapper does NOT have .toggled and does NOT have .subitemclicked, padding-left should be 70px */
    .page-wrapper:not(.toggled):not(.subitemclicked) .page-content {
        padding-left: 70px;
        transition: padding-left 0.4s ease;
    }

    /* Case 4: If .page-wrapper has both .toggled and .subitemclicked, padding-left should still be 280px */
    .page-wrapper.toggled.subitemclicked .page-content {
        padding-left: 280px !important;
        transition: padding-left 0.4s ease;
        overflow-x: unset;
        position: relative;
    }
    .page-wrapper.toggled.subitemclicked .page-content::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: rgba(0, 0, 0, 0.1); */
        pointer-events: auto;
        cursor: default;
        z-index: 1;
    }
    
}
/*----------------show sidebar button----------------*/
#show-sidebar {
    position: fixed;
    left: 0;
    top: 5px;
    border-radius: 4px;
    transition-delay: 0.3s;
    transition: 0.4s ease;
    z-index: 999;
    box-shadow: 8px #00000014;
}
#show-sidebar i {
    color: #bdbdbd;
}

#show-sidebar i:hover {
    color: #fff;
}

.page-wrapper.toggled #show-sidebar {
    left: -40px;
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    height: 91vh;
    position: fixed;
    top: 72px;
    z-index: 10000;
    transition: 1s ease;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}

/*----------------sidebar-content----------------*/
.sidebar-content {
    height: 98%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    border: 1px solid;
    border-color: #EDEDED;
}

.sidebar-content::-webkit-scrollbar {
    display: none;
}


.download_app {
    bottom: 25px;
    left: 5px;
    width: auto;
    padding: 8px;
}

.download_app button {
    width: 100%;
    padding: 6px 10px 6px 10px;
    border-radius: 6px;
    border: 2px solid #002D72;
    background-color: #FFFFFF;
    color: #002D72;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 16px;
}

.sidebar-content.desktop {
    overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.sidebar-wrapper .sidebar-brand>a {
    font-weight: bold;
    font-size: 20px;
    flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
    line-height: 31px;
    width: 100%;
}

/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
    float: left;
}

.sidebar-wrapper .sidebar-header .user-info>span {
    display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search>div {
    padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-border-class {
    border-radius: 8px;
    background-color: var(--UI-background-white, #FFF);
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 17px;
    display: inline-block;
}

.sidebar-menu .sidebar-menu-list {
    margin-top: 0px;
}

.menu-item:hover {
    background-color: #F7F7FB;
    border-radius: 4px;
}

.sidebar-open .menu-item:hover {
    background-color: #F7F7FB;
    border-radius: 4px;
}

.dashboard-dropdown .menu-item.active>a {
    border-radius: 4px;
}

.task-dropdown .menu-item.active>a {
    color: #1E293B !important;
    border-radius: 4px;
    background: var(--primary-base, #DEE2E6);
    border-radius: 4px;
}

.manage-dropdown .menu-item.active>a {
    color: #1E293B !important;
    background: #DEE2E6;
    border-radius: 4px;
}

.billing-dropdown .menu-item.active>a {
    color: white !important;
}

.invoice-dropdown .menu-item.active>a {
    color: white !important;
}

.billing-dropdown.active>a {
    color: #002d72 !important;
}

.invoice-dropdown.active>a {
    color: #002d72 !important;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #002d72 !important;
}

#show-sidebar i {
    color: #002d72 !important;
}

.chiller-theme .sidebar-footer {
    background: transparent !important;
    box-shadow: -5px 0px 10px #ccc !important;
    border-top: none !important;
}

.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 0px 8px 10px;
    font-size: 16px;

}

.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    font-weight: 400;
    font-family: Roboto;

}

.sidebar-wrapper .sidebar-menu ul li a:hover>i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-dropdown a {
    text-align: left !important;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
    font-family: "Font Awesome 6 Free";
    font-weight: 510;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 14px;
    color: #4B5563;
}


.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    width: 100%;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    margin-left: 24px;
    font-size: 13px;
}

.sidebar-dropdown.no-arrow>a::after {
    content: none !important;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}

.sidebar-footer>a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.sidebar-footer>a .notification {
    position: absolute;
    top: 0;
}

.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}

.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
    /* display: inline-block; */
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
    transition: 0.2s ease;
    height: 92vh;
}


.page-wrapper .page-content>div {
    height: 98%;
    overflow-y: scroll;
    /* gap: 24px; */
    background-color: #F9FAFB;
    border-top-left-radius: 24px;
    padding: 32px;
    margin-bottom: 1px;
}

.page-wrapper .page-content {
    overflow-x: hidden;
}

.font-20 {
    font-size: 20px !important;
}

/*------scroll bar---------------------*/

/* Customize scrollbar like Mac OS */
.page-wrapper .page-content>div::-webkit-scrollbar {
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.page-wrapper .page-content>div::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.page-wrapper .page-content>div::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #FFF;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #1E293B;
}


.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #FFF;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div {
    background-color: none !important;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #1E293B;
}

.toggled-sideBar {
    left: 0px !important;
    transition: 1s ease;
}

.chiller-theme .sidebar-footer {
    background: #f5f5f5;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

.icon-wrapper {
    width: 30px;
    height: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
}

.bg-billing {
    background-color: rgba(0, 46, 116, 0.2) !important;
}

.bg-invoice {
    background-color: rgba(0, 46, 116, 0.2) !important;
}

.text-billing:hover {
    color: #002d72 !important;
}

.text-invoice:hover {
    color: #002d72 !important;
}

.link-style {
    padding: 8px 10px;
    text-decoration: none;
    color: inherit !important;
    display: flex;
    align-items: center;
    gap: 10px;
}


.task-drawer-wrapper {
    max-height: 91%;
    top: 72px;
    z-index: 999;
    transition: 0.4s ease;
    background: #FFF;
}

.task-drawer-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.manage-drawer-wrapper {
    max-height: 91vh !important;
    top: 72px;
    z-index: 999;
    transition: 0.4s ease;
    background: #FFF;
}

.manage-drawer-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.logout-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.sidebar-menu {
    height: 100%;
}

.drawer-heading {
    padding: 0px 20px;
    margin-bottom: 20px;
    font-family: 'SFProDisplayBold';
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    color: #1E293B;
}