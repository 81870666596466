/* VisitProfile.css */
.main-container {
    margin-top: 20px;
}

.visit-profile-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.content-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.header-navigation {
    gap: 12px;
    display: flex;
    align-items: center;
}
.content-wrapper {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    min-height: 400px;
}
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.dynamic-table-container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.dynamic-table-container table {
    width: 100%;
    border-collapse: collapse;

}
.header-with-icon {
    display: flex;
    align-items: center; 
    gap: 8px; 
    justify-content: flex-start; 
}

.dynamic-table-container th {
    text-align: left;
    padding: 12px 15px;
    background-color: #E5E7EB;
    color: #333;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e0; 
}

.dynamic-table-container th:last-child,
.dynamic-table-container td:last-child {
    text-align: center;
}

.dynamic-table-container td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e0e0e0;
    color: #666;
}

.custom-pagination {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
}

.custom-pagination span {
    margin: 0 5px;
}

.custom-pagination button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
}

.custom-pagination button:disabled {
    cursor: not-allowed;
    color: #ccc;
}
.custom-pagination  button svg {
    width: 16px;
    height: 16px;
}