.planner-table-container {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
    overflow: hidden;
}

.planner-table {
    width: 100%;
}

.header-colmn {
    height: 48px;
    background-color: #E5E7EB;
    padding: 12px 16px;
}

.colmn-container {
    display: flex;
    gap: 8px;
}

.planner-table tbody td {
    height: 60px;
    padding: 20px 16px;
    vertical-align: middle;
}
.planner-table tbody tr {
    border-bottom: 1px solid #DFE4EA;
}