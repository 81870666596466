.accordian-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
    padding: 24px;
    min-height: 80px;
    overflow: hidden;
    /* Prevents overflowing content during animation */
    transition: height 0.3s ease-in-out;
}



.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.accordian-button {
    width: 32px;
    height: 32px;
    border-radius: 88px;
    background: #F3F4F6;
}

.accordian-row {
    display: flex;
    gap: 10px;
    height: 22px;
    align-items: center;
}


.accordian-content-wrapper {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    /* Smooth height changes */
}

.accordian-content {
    padding: 2px 0px;
}

.accordian-content hr {
    border-color: #DFE4EA;
}