.accordian-view-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.accordian-view-header {
    display: flex;
    height: 48px;
    width: 100%;
    border-radius: 16px;
    background-color: #DFE4EA;
}

.header-col {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 25px;
}

.accordian-nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #DFE4EA;
}