.event-card {
    width: 252px;
    min-height: 150px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.eventcard-content-container {
    height: 22px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.eventcard-content-container img {
    width: 16px;
    height: 16px;
}

.eventcard-content-container hr {
    border: 1px solid #DFE4EA;
    height: 2px;
}