.planner-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.planner-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header-navigation {
    gap: 12px;
    display: flex;
    align-items: center;
}

.chevron-right-icon {
    width: 18px;
    height: 18px;
}

.calender-container {
    width: 1022px;
    height: 790px;
    padding: 16px 24px;
    gap: 16px;
    border-radius: var(--Corner400);
    box-sizing: border-box;
    background-color: #FFFFFF;
}