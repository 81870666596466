.info-window {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    max-width: 250px;
    padding: 10px;
  }
  
  .info-window h4 {
    margin-top: 0;
    color: #002D72; 
    font-size: 1.1rem;
  }
  
  .info-window p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .info-window .info-label {
    font-weight: bold;
  }
  
  .info-window .info-value {
    font-style: italic;
    color: #555;
  }
  
  .info-window .divider {
    border-bottom: 1px solid #ddd;
    margin: 10px 0;
  }
  
  .gm-style-iw-chr {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    overflow: visible;
    height: 1px !important;
}
.marker-position {
  bottom: 6px;
  left: 0;
  position: relative;
}