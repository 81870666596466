@font-face {
    font-family: 'SFProDisplayMedium';
    src: url('../../assets/appleFont/SFProDisplay-Medium.ttf') format('truetype');
}

.calender-container {
    width: 100%;
    height: auto;
    padding: 16px 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    border-radius: var(--Corner400);
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.calendar-header {
    width: 100%;
    height: 62px;
    padding: var(--Spacing200) 0 var(--Spacing200) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation-container {
    width: fit-content;
    height: fit-content;
    gap: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button-container {
    /* min-width: 161px; */
    min-height: 30px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 4px;
    background-color: #F3F4F6;
}

.calendar-list-button-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DFE4EA;
    padding: 4px;
    background-color: #F3F4F6;
    /* gap: 4px; */
}

.custom-button-icon {
    display: flex;
    height: 22px;
    width: 24px;
    padding: 0px 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

/* Active state */
.custom-button-icon.active {
    background-color: #FFFFFF;
    border-radius: 6px;
}

.custom-button-icon img {
    width: 16px;
    height: 16px;
}

.custom-button {
    min-height: 22px;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

/* Active state */
.custom-button.active {
    background-color: #FFFFFF;
    border-radius: 6px;
}

.month-container {
    width: fit-content;
    height: 24px;
    display: flex;
    gap: 8px;
}

.icon {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.draft {
    min-width: 53px;
    min-height: 26px;
    padding: 3px 8px;
    border-radius: 4px;
    background: #7777771A;
}

.draft.draft-status {
    background: #7777771A;
}

.draft.pending-status {
    background: #D977061A;
}

.draft.rejected-status {
    background: #E10E0E1A;
}

.draft.approved-status {
    background: #1A82451A;
}

.draft.default-status {
    background: #7777771A;
    /* Fallback */
}

.submit-plan {
    width: fit-content;
    height: 28px;
    padding: 4px 12px;
    border-radius: 6px;
    background: #002D72;
    transition: background 0.3s ease;
}

.submit-plan:hover {
    background: #001136;
}


/* new calendar css */
.calendar-weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: left;
    gap: 10px;
}

.calendar-day-name {
    padding: 10px;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #E7EEF8;
    border-collapse: collapse;
}

.calendar-day {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1.4;
    padding: 10px;
    gap: 4px;
    cursor: pointer;
    border: 1px solid #E7EEF8;

    /* Ensures no overflow changes the size */
    overflow: hidden;
    box-sizing: border-box;
}

.event-container {
    max-width: 100%;
    max-height: 60%;
    /* background-color: rgba(0, 123, 255, 0.1); */
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 4px;
}

.event-detail {
    max-width: min-content;
    min-height: 20px;
    padding: 0px 4px 0px 4px;
    /* gap: 10px; */
    border-radius: 4px;
    background-color: #0085FF1A;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    color: #0085FF;
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;

    /* Truncate overflowing text */
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.extra-count {
    color: #384153;
    font-family: 'SFProDisplayMedium';
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

/* week calender */
.week-calendar-day-name {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;
}

/* new */

.week-calendar-day-name {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;
}

/* Week hour row to display time and days */
.calendar-week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* border: 1px solid #E7EEF8; */
    border-collapse: collapse;
}

.week-hour-row {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr);
    align-items: center;
    gap: 10px;
}

.calendar-week-weekdays {
    margin-left: 55px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: left;
    gap: 8px;
}

/* day css */

.day-header {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 45px;
}

.day-hour-row {
    display: grid;
    grid-template-columns: 50px repeat(1, 1fr);
    align-items: center;

}

.day-hour {
    display: flex;
    flex-direction: column;
    height: 56px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #E7EEF8;
}

/* new css */
.otherbutton-container {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 458px;
    height: 30px;
    align-items: center;
}

.myplan-employeeselect-container {
    display: flex;
    gap: 16px;
    width: 253px;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
}

.employee-select {
    width: 150px;

}

.my-plan-button {
    width: 87px;
    height: 30px;
    padding: 4px 10px;
    gap: 4px;
    border-radius: 44px;
    border: 1px solid #DFE4EA;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F4F6;
}

.my-plan-button-active {
    background-color: #E2E7FF;
    border-color: #004BB4;
}

.reject-button {
    min-width: 81px;
    height: 30px;
    padding: 4px 12px;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #F23030;
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.approve-button {
    min-width: 92px;
    height: 30px;
    padding: 4px 12px;
    gap: 8px;
    border-radius: 6px;
    background-color: #046C4E;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}