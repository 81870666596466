.multi-step-wrapper ol {
    display: flex;
    padding-right: 2rem;
}

.multi-step-wrapper li {
    flex: 1;
    border-bottom: 6px solid #002D72;
    height: 50px;
}

.multi-step-wrapper li:nth-child(1) {
    border-image: linear-gradient(to left,
            #002D72 50%, #FFF 50%) 100% 1;
}

.multi-step-wrapper li:nth-child(4) {
    border-image: linear-gradient(to right,
            #002D72 50%, #FFF 50%) 100% 1;
}

.multi-step-wrapper li span {
    display: none;
}

.multi-step-wrapper li::before {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    line-height: 2em;
    bottom: -30px;
}

.multi-step-wrapper .go433304200::before {
    background-color:
        #002D72;
    font-weight: 900;
}

.multi-step-wrapper .go3842760039::before {
    background-color:
        #002D72;
    font-weight: 900;
    line-height: 1.8em;
}

.multi-step-wrapper .go2335061104::before {
    content: '';
    background-color:
        #002D72;
}

.multi-step-wrapper div button {
    width: 120px;
    height: 40px;
    background-color:
        #002D72;
    color: white;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 1.3em;
}

.task-pagination-wrapper {
    float: right;
    margin-right: 30px;
    margin-top: 20px;
}

.task-pagination-wrapper button {
    margin-right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 6px;
}

.task-pagination-wrapper button.active {
    background-color:
        #002D72;
    color: #FFF;
}

.task-table-header {
    font-weight: 700 !important;
    font-size: 14px !important;
    font-style: normal;
    text-transform: none !important;
}

.task-filter-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 5px;
    padding: 10px;
}

.task-filter-wrapper div .basic-multi-select .select__control {
    height: auto;
    width: 100%;
}

.task-filter-reset-btn {
    width: 100%;
}

.task-type {
    border: 2px solid #ccc;
    border-radius: 7px;
    display: block;
    font-size: 1em;
    padding: 10px;
    font-weight: 600;
    width: 90%;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.task-type:focus-visible {
    border: 2px solid #ccc !important;
}

.task-type span {
    font-size: 28px;
}

.heading_notes {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notes_input {
    border-radius: 8px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
    margin: 9px;
    padding: 6px;
}

.notes_input::placeholder {
    padding: 3px;
}

.notes_paragraph {
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 14px;
    align-items: flex-start;
    gap: var(--1, 8px);
    margin: 9px;
}

.notes-avatar-wrapper {
    border-radius: 24px;
    background: #D9D9D9;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 9px;
}

.no-line-break {
    white-space: nowrap;
}

.notes_name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

/* Customize scrollbar for webkit browsers */
::-webkit-scrollbar {
    /* width: 16px; */
}

::-webkit-scrollbar-track {
    /* background-color: #fff; */
}

::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* Hide scrollbar buttons (not supported in Firefox and IE) */
::-webkit-scrollbar-button {
    /* display: none; */
}