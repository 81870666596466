.dynamicmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10010;
}

.dynamicmodal-form-group textarea:focus,
.dynamicmodal-form-group input:focus {
  outline: none;
  border: none;
}

.dynamicmodal-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.dynamicmodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dynamicmodal-header-left .icon {
  width: 48px;
  height: 48px;
  padding: 12px ;
  border-radius: 10px;
  border: 1px solid #E9EAEB;
 background: #FFFFFF;
}

.dynamicmodal-header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dynamicmodal-header-left .text p:first-child {
  font-weight: bold;
}

.dynamicmodal-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dynamicmodal-form-group {
  display: flex;
  flex-direction: column;
}

.dynamicmodal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dynamicmodal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
