.lds-dual-ring {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
  .colored::after{
    border: 6px solid #31353D !important;
    border-color: #31353D transparent #31353D transparent !important;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }