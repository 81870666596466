/* .task-details {
  background-color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin: 15px auto;
    border-radius: 10px;
    max-width: 770px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    min-height: auto;
    overflow: scroll;
  } */

/* .top-heading {
    height: 48px;
    width: 688px;
    display: space-between;
  } */
/* .title {
    height: 42px;
    width: 228px;
  } */


/* .other-details{
    color: #909090;
    background-color: #FF4D001A;
    height: 28px;
    border-radius: 4px;
  } */
/* .priority_name{
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .priority_color{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statuses{
    margin-left: -9%;
    display: flex;
    justify-content: center;
    text-align: center;
    gap:15px;
  }
  .task-details h1 {
    font-size: 24px;
    color: 	#000000;
    font-family: Roboto;
    font-weight: 500;
    
    
    
  }
  
  .task-details h2 {
    font-size: 22px;
    color: 	#505050;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .task-details p {
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 20px;
    width: auto;
    display: flex;
    align-items: center;
    text-align: center;
    height: auto;
    border-radius: 4px;
    justify-content: space-between;
  }
  
  .task-details ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
  } */

/* .task-details li {
    margin-bottom: 5px;
  }
   
  .map-container {
    height: 200px;
    width: 688px;
    margin-top: 20px;
    margin-bottom: 40px;
  
  }
  .icon-wrapper-detail{
    border-radius: 15px;
  } */

/* .bg-task-dark{
    background-color: rgba(0, 46, 116, 0.2) !important;
  }
  .bg-manage-dark{
    background-color: rgba(222, 12, 21, 0.3) !important;
  }
  .bg-billing-dark{
    background-color: rgba(0, 133, 44, 0.4) !important;
  }
  .bg-invoice-dark{
    background-color: rgba(255, 178, 10, 0.4) !important;
  }
  .bg-task{
    background-color: rgba(0, 46, 116, 0.2) !important;
  }
  .bg-manage{
    background-color: rgba(222, 12, 21, 0.2) !important;
  }
  .bg-billing{
    background-color: rgba(0, 133, 44, 0.2) !important;
  }
  .bg-invoice{
    background-color: rgba(255, 178, 10, 0.2) !important;
  }
  .text-task{
    color: #002D72 !important;
  }
  .text-manage{
    color: #DE0C15 !important;
  }
  .text-billing{
    color: #00852C !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-invoice{
    color: #FFB20A !important;
  } */

/* .detail-container {

   margin-bottom: 44px;
  }
  .created_name{
    border-radius: 4px;
background-color: rgba(255, 77, 0, 0.10);
display: flex;
align-items: center;
justify-content: center;
  }
  .other-detail {
    margin: 10px;
    margin-bottom: 20px;
  }
  .other-detail-title {
    width: 648px;
    height: 23px;
    font-size: 18px;
    font-family: Roboto;
    line-height: 23.44px;
    font-weight: 500;
    color: #3A3A3A7A;
    margin: 8px;
  }
  .image-wrapper {
    width: 24px;
    height: 24px;
    border: 0.4px;
    color: #002D72;
  }
  .other-detail-text {
    width: 648px;
    height: 25px;
    font-size: 18px;
    font-family: Roboto;
    line-height: 23.25px;
    font-weight: 500;
    color: #272727;
    margin-left: 38px;

  } */
/* .subtasks-container {
    margin-top: 32px;
  }
  .subtasks-title {
    width: 100px;
    height: 28px;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 28.13px;
    margin-bottom: 24px;
  }

  .statusHistory-container {
    width: 688px;
    height: 248px;
    margin-top: 32px;
  }
  .statusHistory-title {
    width: 153px;
    height: 28px;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 28.13px;
    margin-bottom: 24px;
  }
  .modal_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
  } */

/* 
  .modal-container {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 80%;
  }
   .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .remarks_textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  
  .btn_remarks {
    padding: 10px 20px;
    background-color: #002D72;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_remarks:hover {
    background-color: #001F4F;
  }   */



.set_text_gap {
  display: flex;
  flex-direction: column;
  gap: 16px;

}

.label_data {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.details_text {
  color: var(--Neutral-8, #595959);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}