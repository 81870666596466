/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    /* color: #002D72 !important; */
    color: #2196F3 !important;
    font-weight: 700 !important
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: rgba(11, 100, 233, 0.8) !important;
    font-weight: 700 !important
}
.nav-tabs .nav-link {
    font-weight: 700;
}
.summary-button{
width: 206px;
height: 38.8px;
padding: 6px 15px 6px 15px;
border-radius: 2px;
border: 1px;
}

.makeStyles-root-1{
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}
