.bg-12 {
  width: 100%;
  /* position: fixed; */
  z-index: 99;
  /* border-radius: 12px; */
  background-color: #FFF;
  /* border-bottom: 1px solid black; */
  /* box-shadow: 1px 1px 1px 0 rgb(0 0 0 / 16%); */
  /* margin-top: 16px;  */
  height: 72px;


}

.header-avatar-wrapper {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.header-avatar-wrapper div {
  background: #002D72;
  border-radius: 100%;
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  margin-top: 0px;
  align-items: center;
}

.header-avatar-wrapper div p {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0px;
}

.avatar-dropdown-menu {
  top: 55px !important;
  border-radius: 4px;
  background: var(--background-paper-elevation-3, #FFF);

  /* elevation/3 */
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);
  width: auto;
  height: auto;
  left: -16px;
}

.dropdown-menu {
  border-radius: 4px !important;
}

.dropdown-toggle::after {
  margin-top: auto;
  margin-bottom: auto;
}

.name_font {
  color: var(--Light-Theme-Heading-Text, #151924);
  /* Body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
}

.designation_font {
  color: var(--grey-500, var(--grey-500, #505157));
  /* Footnote/description */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.profile_section {
  display: flex;
  gap: 20px;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  cursor: pointer;

  /* margin-top: 3px; */
}

.logout_section {
  display: flex;
  gap: 20px;
  padding: 0px 16px;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.profile_section:hover,
.logout_section:hover {
  background-color: #F7F7FB;
}

.line_hr {
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: auto;
}

.icon-buttons-container {
  display: flex; /* Ensures the icons are in a row */
  align-items: center; /* Vertically aligns icons */
  justify-content: flex-end; /* Pushes the icons to the right end */
  gap: 12px; /* Adds space between the icons */
  margin-right: 16px; /* Adjust this as needed for proper spacing from the right edge */
}